import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";

import sherinblog from "../../../assets/images/BlogsPage/sherinprofile.webp";


import { Helmet } from "react-helmet";

import axios from "axios";

function Blogpagesix(props) {
  const [posts, setPosts] = useState([]);
  const removeFigureTag = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, "text/html");
    const figures = doc.querySelectorAll("figure");
    const paragraphs = doc.querySelectorAll("p");
    // Add margin-top to each <p> tag
    paragraphs.forEach((p) => {
      p.style.marginTop = "20px";
    });
    // Remove <figure> elements
    figures.forEach((figure) => {
      figure.parentNode.removeChild(figure);
    });

    return doc.body.innerHTML;
  };

  const getPostData = () => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@trackgenesisblogs"
      )
      .then((res) => {
        setPosts(res.data.items);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Cryptocurrency | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | Blockchain | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Luxury Brands Use Blockchain for Trust"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      </Helmet>

      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        id="luxurybrands-blockchain"
      >
        <Flex flexDir={"column"}>
          {/* heading Section */}
          <Flex
            backgroundColor={"black"}
            width="100%"
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"center"}
            height={"400px"}
          >
            <Heading
              className="heading"
              color={"#94DAF8"}
              fontSize={{ md: "33px", base: "25px", lg: "37px" }}
              fontWeight="bold"
              textShadow="1px 1px #000000"
              mt={{ base: "36px", md: "35px", lg: "10px" }}
              textAlign="center"
              maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
            >
              {posts.length > 0 && posts[1].title}
            </Heading>
          </Flex>

          <Navbar />
          <Mobilenavbar />

          <Flex
            backgroundColor={"black"}
            alignItems={"center"}
            justifyContent="center"
            width="100%"
          >
            <Image
              objectFit={"cover"}
              borderRadius={"10px"}
              mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
              ml={{ base: "-5%", lg: "-5%" }}
              width={{ base: "91%", md: "80%", lg: "70%" }}
              src="https://cdn-images-1.medium.com/max/1024/1*ce863znAlpKyPxr01czsFw.png"
            />
          </Flex>

          <Flex
            flexDir={"column"}
            position={"relative"}
            className="mainborder"
            my={{ base: "2%", md: "2%", lg: "2%" }}
            mx={{ base: "10px", md: "15px", lg: "30px" }}
            backgroundColor={"rgba(27, 21, 78, 0.38)"}
            backdropFilter="auto"
            backdropBlur="8px"
            pb={"100px"}
          >
            <Flex flexDir={"column"}>
              <Flex
                color={"white"}
                className="content_font"
                width={"100%"}
                px={{ base: "10%", md: "15%", lg: "20%" }}
                fontSize={{ base: "15px", md: "16px", lg: "17px" }}
                flexDirection="column"
                py={"20px"}
              >
                {/* Render blog post content here */}
                {posts.length > 0 && (
                  <div key={posts[1].guid}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeFigureTag(posts[1].content),
                      }}
                    />
                    <a
                      href={posts[1].link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                  </div>
                )}
              </Flex>
              <Flex my={"20px"} width={"100%"}>
                <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                  <Text
                    color={"white"}
                    className="content_font"
                    fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                  >
                    - by Sherin Thomas
                  </Text>
                  <Image
                    objectFit={"contain"}
                    ml={{ base: "10px", md: "10px", lg: "30px" }}
                    mt={"-10px"}
                    width={{ base: "30px", md: "40px", lg: "55px" }}
                    src={sherinblog}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpagesix;
