import { useState, useEffect, useRef } from "react";
import axios from "axios";
import chatbot from "../../../assets/images/HomePage/robo.gif";
import { Send, ChevronDown, User, X } from "lucide-react";
import { API_BASE_URL } from "../../../config";
import { motion, AnimatePresence } from "framer-motion";
import { marked } from "marked";
import DOMPurify from "dompurify";
import {
  Box,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  Icon,
  Flex,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const chatContainerRef = useRef(null);

  // Colors for Dark Mode
  const borderColor = useColorModeValue("gray.500", "gray.700");
  const userBg = useColorModeValue("white", "blue.400");
  const botBg = useColorModeValue("gray.700", "gray.600");
  const inputBg = useColorModeValue("gray.700", "gray.800");
  const textColor = useColorModeValue("white", "gray.200");

  useEffect(() => {
    // Clear chat history when the component is mounted
    const clearChat = async () => {
      try {
        await axios.post(`${API_BASE_URL}/clear-memory`);
      } catch (error) {
        console.error("Error clearing chat:", error);
      }
    };
    setTimeout(() => setShowTooltip(true), 2000);
    clearChat();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { sender: "user", text: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/chat`, {
        query: input,
      });

      const responseText = response.data.response;
      setMessages((prev) => [...prev, { sender: "bot", text: responseText }]);
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: "Oops! Something went wrong. Try again." },
      ]);
    }
    setLoading(false);
  };

  const renderMarkdown = (text) => {
    const sanitizedHtml = DOMPurify.sanitize(marked(text)); // Prevent XSS attacks
    return (
      <div
        className="markdown-content"
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    );
  };

  return (
    <Box zIndex={1000}>
      {/* Chat Button */}
      {!isOpen && (
        <Box
          position="fixed"
          bottom={{ md: "17px", base: "4px", lg: "17px" }}
          right={{ md: "70px", base: "0px", lg: "70px" }}
          bg="transparent"
          borderRadius="full"
          p={4}
          boxShadow="lg"
          _hover={{ transition: "0.2s ease-in-out" }}
        >
          <Box position="relative">
            {showTooltip && (
              <motion.div
                initial={{ opacity: 0, y: 10, x: 10 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, y: 10, x: -10 }}
                transition={{ duration: 0.3 }}
                style={{
                  position: "absolute",
                  top: "-50px",
                  right: "28px",
                  background: "#B989DD",
                  padding: "10px",
                  borderRadius: "17px 17px 17px 17px ",
                  boxShadow: "md",
                  zIndex: -1,
                  // height: "70px",
                  border: "none", // Remove the original border
                  outline: "4px solid rgba(184,184,184,0.3)", // Add an outline instead
                  display: "flex",
                }}
              >
                <Text
                  cursor={"pointer"}
                  color={"white"}
                  width={"230px"}
                  // marginRight={"-15px"}
                  onClick={() => {
                    setShowTooltip(false);

                    setIsOpen(true);
                  }}
                  alignSelf={"center"}
                  fontSize="12px"
                >
                  Hi there! Welcome to TrackGenesis. How can I assist you today?
                </Text>
                <Icon
                  cursor={"pointer"}
                  onClick={() => {
                    setShowTooltip(false);

                    setIsOpen(false);
                  }}
                  as={X}
                  w={4}
                  h={4}
                  marginTop={-1}
                  marginRight={-1}
                />
                <Box
                  position="absolute"
                  bottom="-6px"
                  right="20px"
                  w="0"
                  h="0"
                  borderLeft="8px solid transparent"
                  borderRight="8px solid transparent"
                  borderTop="8px solid  #B989DD"
                />
              </motion.div>
            )}
            <Image
              cursor={"pointer"}
              onClick={() => {
                setShowTooltip(false);
                setIsOpen(true);
              }}
              src={chatbot}
              boxSize={{ base: 16, md: 16, lg: 16 }}
              _hover={{ boxSize: 20, transition: "0.2s ease-in-out" }}
            />
          </Box>
        </Box>
      )}

      {/* Chat Window */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 50, scale: 0.9 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "0px",
              zIndex: 1000,
            }}
          >
            <Box
              w={{ md: "500px" }}
              mx={1}
              borderWidth="1px"
              borderRadius={30}
              p={4}
              color={textColor}
              borderColor={borderColor}
              boxShadow="2xl"
              sx={{
                background:
                  "linear-gradient(135deg, rgba(8, 5, 66, 0.73), rgba(75, 120, 216, 0))",
              }}
            >
              {/* Header */}
              <Flex justify="space-between" align="center" mb={3}>
                <Text fontWeight="bold" fontSize="lg" pl={4}>
                  AI Assistant
                </Text>
                <Button
                  size="sm"
                  variant="ghost"
                  color="gray.400"
                  onClick={() => setIsOpen(false)}
                >
                  <Icon as={ChevronDown} w={6} h={6} />
                </Button>
              </Flex>

              {/* Messages */}
              <VStack
                spacing={3}
                align="stretch"
                overflowY="auto"
                maxH="400px"
                p={2}
                pb={14}
                ref={chatContainerRef}
              >
                <HStack alignSelf={"flex-start"}>
                  {messages.length === 0 && (
                    <Image src={chatbot} boxSize={12} />
                  )}
                  <Box
                    bg={botBg}
                    color="white"
                    p={3}
                    borderRadius="xl"
                    boxShadow="md"
                    maxW="75%"
                  >
                    {renderMarkdown(
                      "Hello! I'm Trackgen Robo, your AI assistant. How can I help you today?"
                    )}
                  </Box>
                </HStack>
                {messages.map((msg, index) => (
                  <HStack
                    key={index}
                    alignSelf={
                      msg.sender === "user" ? "flex-end" : "flex-start"
                    }
                    maxW="100%"
                  >
                    {msg.sender === "bot" && (
                      <Image src={chatbot} boxSize={12} />
                    )}
                    <Box
                      bg={msg.sender === "user" ? userBg : botBg}
                      color={msg.sender === "user" ? "black" : "white"}
                      p={3}
                      borderRadius="xl"
                      boxShadow="md"
                      maxW="100%"
                    >
                      {renderMarkdown(msg.text)}
                    </Box>
                    {msg.sender === "user" && (
                      <Icon as={User} w={5} h={5} color="blue.400" />
                    )}
                  </HStack>
                ))}
                {loading && (
                  <Flex color="gray.400" alignItems={"center"}>
                    <Image src={chatbot} boxSize={12} />
                    &nbsp; Thinking...
                  </Flex>
                )}
              </VStack>

              {/* Input Field */}
              <HStack p={2} borderTopWidth="1px" borderColor={borderColor}>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") sendMessage(); // Call sendMessage on Enter key press
                  }}
                  placeholder="Type a message..."
                  bg={inputBg}
                  color="white"
                  borderRadius="xl"
                />
                <Button
                  colorScheme="green"
                  onClick={sendMessage}
                  disabled={loading || input.trim() === ""}
                >
                  <Icon as={Send} w={5} h={5} />
                </Button>
              </HStack>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Chatbot;
