import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aboutus from "./views/pages/Aboutus";
import Mainpage from "./views/pages/Mainpage";
import Newsroom from "./views/pages/Newsroom";
import Contactus from "./views/pages/Contactus";
import Career from "./views/pages/Career";
import Blogs from "./views/pages/Blogs";

import ArVr from "./views/pages/ArVr";
import Footer from "./components/sections/Footer";
import IndustrySolutions from "./views/pages/IndustrySolutions";
import Home from "./components/sections/home/Home";
import { Helmet } from "react-helmet";
import Nft from "./views/pages/Nft";
import Maintainance from "./views/pages/Maintainance/Maintainance";

import Blogpageone from "./views/pages/Blogs/Blogpageone";
import Blogpagetwo from "./views/pages/Blogs/Blogpagetwo";
import Blogpagethree from "./views/pages/Blogs/Blogpagethree";
import Blogpagefour from "./views/pages/Blogs/Blogpagefour";
import Blogpagefive from "./views/pages/Blogs/Blogpagefive"
import SoftwareSolutions from "./views/pages/SoftwareSolutions";
import Products from "./views/pages/Products";
import CaseStudy from "./views/pages/CaseStudy";
import Blogpagesix from "./views/pages/Blogs/Blogpagesix";
import BlogSoftware from "./views/pages/Blogs/BlogSoftware";
import BlogCertificate from "./views/pages/Blogs/BlogCertificate";
import BlogPassport from "./views/pages/Blogs/BlogPassport";

function App() {
  return (
    <div>
      <Helmet>
        <title>
          TrackGenesis&reg; | Top Software & Blockchain Company | Scotland
        </title>
        <meta
          name="description"
          content="TrackGenesis is the leading software and blockchain development company that supports sustainable business by ensuring transparency and immutability."
        />
        <link href="https://trackgenesis.com" rel="canonical"/>

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://trackgenesis.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="TrackGenesis | Top Software & Blockchain Company | Scotland "
        />
        <meta
          property="og:description"
          content="TrackGenesis is the leading software and blockchain development company that supports sustainable business by ensuring transparency and immutability."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dop6riusd/image/upload/v1705656373/banner_aj9ss2.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://trackgenesis.com/" />
        <meta
          name="twitter:title"
          content="TrackGenesis | Top Software & Blockchain Company | Scotland "
        />
        <meta
          name="twitter:description"
          content="TrackGenesis is the leading software and blockchain development company that supports sustainable business by ensuring transparency and immutability."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dop6riusd/image/upload/v1705656373/banner_aj9ss2.png"
        />
  
      </Helmet>
      <BrowserRouter>
        <Routes>
        {/* <Route index element={<Maintainance />} /> */}
          <Route index element={<Mainpage />} />
          <Route path="home" element={<Home />}  />
          <Route path="about-us" element={<Aboutus />} />
          <Route path="footer" element={<Footer />} />
          <Route path="/services/blockchain" element={<IndustrySolutions />} />
          <Route path="/services/ar-vr" element={<ArVr />} />
          <Route path="/services/nft-platform" element={<Nft/>} />
          <Route path="/services/software-development" element={<SoftwareSolutions/>} />
          <Route path="media-newsroom" element={<Newsroom />} />
          <Route path="contact-us" element={<Contactus />} />
          <Route path="careers" element={<Career />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="products" element={<Products />} />
          <Route path="case-study" element={<CaseStudy />} />
          <Route path="/blogs/blog-blockchain" element={<Blogpageone/>} />
          <Route path="/blogs/blog-blockchain-technology" element={<Blogpagetwo/>} />
          <Route path="/blogs/blog-blockchain-cryptocurrency" element={<Blogpagethree/>} />
          <Route path="/blogs/blog-blockchain-supplychain" element={<Blogpagefour/>} />
          <Route path="/blogs/blog-blockchain-palm-oil" element={<Blogpagefive/>} />
          <Route path="/blogs/brands-blockchain" element={<Blogpagesix/>} />
          <Route path="/blogs/your-perfect-software-development-partner-TrackGenesis-Aberdeen" element={<BlogSoftware/>} />
          <Route path="/blogs/How-Blockchain-Technology-Help-Education-and-Training-Institutions-to-Fight-Fake-Certificates" element={<BlogCertificate/>} />
          <Route path="/blogs/Digital-Product-Passport" element={<BlogPassport/>} />
          <Route path="404" element={<Maintainance />} />
          <Route path="/:error" element={<Mainpage/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
